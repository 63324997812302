"use client";

import { JOB, MACHINE, REPOSITORY, SERVICE } from "@dashboard/resources/static";
import { ChevronDown, Plus } from "@jengaicons/react";
import { Button } from "@primitives/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@primitives/dropdown";
import { Link } from "@primitives/link";

const sectionOne = [
  {
    name: REPOSITORY.newLabel,
    href: REPOSITORY.new.route,
    icon: REPOSITORY.icon,
  },
  { name: SERVICE.newLabel, href: SERVICE.new.route, icon: SERVICE.icon },
  { name: JOB.newLabel, href: JOB.new.route, icon: JOB.icon },
  { name: MACHINE.newLabel, href: MACHINE.new.route, icon: MACHINE.icon },
];

export function NewServiceMenu() {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" prefix={<Plus />} suffix={<ChevronDown />}>
          New
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" sideOffset={8}>
        <DropdownMenuGroup>
          {sectionOne.map((i) => (
            <DropdownMenuItem key={i.name} asChild>
              <Link className="h-full" prefix={i.icon} href={i.href}>
                {i.name}
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
