"use client";

import { cn } from "@/lib/utils";
import { EntityParams } from "@dashboard/entity/types";
import { Separator } from "@primitives/generic";
import { Row } from "@primitives/layout";
import { Link } from "@primitives/link";
import { useParams, usePathname } from "next/navigation";
import { useMemo } from "react";

const generateSegments = (path: string) => {
  return path?.split("/").filter((i) => i.trim());
};

const isRepoRoute = (path: string) => {
  const repoIndicators = [
    "tree",
    "blob",
    "files",
    "blame",
    "edit",
    "stars",
    "watching",
  ];
  const segments = generateSegments(path);

  console.log("segments", segments);
  return repoIndicators.includes(segments[2]);
};

const generateCrumbs = (path: string) => {
  const segments = generateSegments(path);
  if (isRepoRoute(path) && segments.length > 2) {
    return [segments[1]];
  }
  return segments.slice(1, 3) as [string, string];
};

const servicePageMap = {
  apps: "overview",
  "container-registry": "tags",
  datasets: "",
  code: "",
  models: "",
  "fine-tuning": "jobs",
  "inference-endpoints": "overview",
  services: "overview",
};

export function HeaderBreadcrumbs(props: { className?: string }) {
  const path = usePathname();
  const segments = useMemo(() => generateSegments(path || "") || [], [path]);
  const crumbs = useMemo(() => generateCrumbs(path || ""), [path]);

  const params = useParams<EntityParams>();

  if (crumbs[0] === "settings" || !params?.entityName) return;

  if (crumbs.length > 0) {
    if (segments.length > 1)
      return (
        <Row className={cn("ml-2 hidden gap-2 md:flex", props?.className)}>
          <Row className="gap-2">
            <Separator className="block shrink-0 rotate-12 self-center" />
            <Link color="soft" href={`/${params?.entityName}/${crumbs[0]}`}>
              {crumbs[0]}
            </Link>
          </Row>

          {!isRepoRoute(path || "") && segments.length > 2 && (
            <Row className="gap-2">
              <Separator className="relative block rotate-12 self-center" />
              <Link
                className="text-headingSm leading-headingSm font-semibold"
                href={`/${params?.entityName}/${crumbs[0]}/${crumbs[1]}/${
                  servicePageMap[crumbs[0] as keyof typeof servicePageMap]
                }`}
              >
                {crumbs[1]}
              </Link>
            </Row>
          )}
        </Row>
      );
  }

  return null;
}
