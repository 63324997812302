"use client";

import { Link } from "@primitives/link";
import { ReactNode, useMemo } from "react";

import { HeaderBreadcrumbs } from "../shared/breadcrumbs";

export function HeaderLinks(props: { standalone?: boolean }) {
  return <HeaderBreadcrumbs className="ml-4" />;
}

export function HeaderLink(props: { children: ReactNode; href: string }) {
  return (
    <Link
      href={props.href}
      className="text-bodyLg leading-bodyLg text-soft pointer-events-none px-4 py-2 font-medium md:p-0"
    >
      {props.children}
    </Link>
  );
}
